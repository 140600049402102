<template>
  <v-dialog
    value="true"
    scrollable
    width="50%"
    >
    <v-card>
      <v-toolbar dark color="primary" dense>
        Обсуждение карты: {{title}}
        <v-spacer></v-spacer>
        <v-btn icon class="ml-3" small dark @click="$emit('close', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            v-if="sortedComments.length > 0"
            cols="10"
            v-for="comment in sortedComments"
            :key="comment.id"
            :offset="comment.createdBy == userId ? 2 : 0"
            class="pa-3"
            >
            <Comment :comment="comment"/>
          </v-col>
          <v-col
            v-if="sortedComments.length === 0"
            cols="12"
            class="px-4 pt-4 text-center"
            >
            Нет ни одного коментария
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-textarea
          label="Коментарий"
          :rows="3"
          hide-detail
          outlined
          v-model="message"
          >
          <template v-slot:append-outer>
            <v-btn icon color="primary" @click="send">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Comment from './components/Comment.vue'

export default {
  props: ['title', 'processTemplateId'],
  components: { Comment },
  data() {
    return {
      comments: [],
      message: '',
    }
  },
  computed: {
    userId() {this.$store.state.user.profile.userId },
    sortedComments() {
      return this.comments.sort((a, b) => b.createdAt - a.createdAt)
    }
  },
  methods: {
    send() {
      this.$axios.post('/manufacture/process-template-chat/create', {
        processTmplId: this.processTemplateId,
        message: this.message,
      }).then(r => {
        this.comments.push(Object.assign({}, r.data))
      }).catch(() => {})
    }
  },
  mounted() {
    this.$axios.get('/manufacture/process-template-chat/index', { params: {
      processTmplId: this.processTemplateId
    }}).then(r => {
      this.comments = r.data.slice()
    })
  }
}
</script>

